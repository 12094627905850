ul[data-v-915d7a8a] {
  list-style: none;
}[dir] ul[data-v-915d7a8a] {
  padding: 0;
  margin: 0;
}
[dir] p[data-v-915d7a8a] {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */
.suggestion-group-title[data-v-915d7a8a] {
  font-weight: 500;
}
[dir] .suggestion-group-title[data-v-915d7a8a] {
  padding: 0.75rem 1rem 0.25rem;
}
[dir] .suggestion-group-suggestion[data-v-915d7a8a] {
  padding: 0.75rem 1rem;
}
[dir] .suggestion-current-selected[data-v-915d7a8a] {
  background-color: #f8f8f8;
}
[dir] .dark-layout .suggestion-current-selected[data-v-915d7a8a] {
  background-color: #161d31;
}