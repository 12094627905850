[dir] .customizer-section[data-v-52667d36] {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;
}
[dir] .dark-layout .customizer-section[data-v-52667d36] {
  border-color: #3b4253;
}
[dir=ltr] .customizer-section #skin-radio-group[data-v-52667d36]  .custom-control-inline {
  margin-right: 0.7rem;
}
[dir=rtl] .customizer-section #skin-radio-group[data-v-52667d36]  .custom-control-inline {
  margin-left: 0.7rem;
}
[dir] .customizer-section .form-group[data-v-52667d36] {
  margin-bottom: 1.5rem;
}
[dir] .customizer-section .form-group[data-v-52667d36]:last-of-type {
  margin-bottom: 0;
}
.customizer-section .form-group[data-v-52667d36]  legend {
  font-weight: 500;
}
[dir] .mark-active[data-v-52667d36] {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.ps-customizer-area[data-v-52667d36] {
  height: calc(100% - 83px);
}