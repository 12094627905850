/*=========================================================================================
    File Name: vertical-menu.scss
    Description: A classic vertical modern style menu with expand and collops support. It support
    light & dark version, flipped layout, right side icons, native scroll and borders menu
    item separation.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.vertical-layout.vertical-menu-modern .main-menu {
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0s;
  backface-visibility: hidden;
}
[dir] .vertical-layout.vertical-menu-modern .main-menu {
  transform: translate3d(0, 0, 0);
}
.vertical-layout.vertical-menu-modern .main-menu .navigation li a {
  align-items: center;
}
.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg,
.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a i {
  height: 20px;
  width: 20px;
  font-size: 1.45rem;
  flex-shrink: 0;
}
[dir=ltr] .vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg, [dir=ltr] .vertical-layout.vertical-menu-modern .main-menu .navigation > li > a i {
  margin-right: 1.1rem;
}
[dir=rtl] .vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg, [dir=rtl] .vertical-layout.vertical-menu-modern .main-menu .navigation > li > a i {
  margin-left: 1.1rem;
}
.vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content > li > a svg,
.vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content > li > a i {
  font-size: 11px;
  height: 11px;
  width: 11px;
}
[dir=ltr] .vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content > li > a svg, [dir=ltr] .vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content > li > a i {
  margin-right: 1.45rem;
}
[dir=rtl] .vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content > li > a svg, [dir=rtl] .vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content > li > a i {
  margin-left: 1.45rem;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
  width: 260px;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation .navigation-header .feather-more-horizontal {
  display: none;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation > li > a > i:before,
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation > li > a > svg:before {
  height: 20px;
  width: 20px;
  font-size: 1.45rem;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub > a:after {
  content: "";
  height: 1.1rem;
  width: 1.1rem;
  display: inline-block;
  position: absolute;
  top: 14px;
  transition: all 0.2s ease-out;
}
[dir] .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub > a:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.1rem;
  transform: rotate(0deg);
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub > a:after {
  right: 20px;
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub > a:after {
  left: 20px;
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub.open:not(.menu-item-closing) > a:after {
  transform: rotate(90deg);
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub.open:not(.menu-item-closing) > a:after {
  transform: rotate(-90deg);
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-expanded .footer {
  margin-left: 260px;
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-expanded .footer {
  margin-right: 260px;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header {
  width: 80px;
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header {
  float: left;
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header {
  float: right;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header .modern-nav-toggle {
  display: none;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header.expanded {
  width: 260px;
  z-index: 1000;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header.expanded .modern-nav-toggle {
  display: block;
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .navbar.fixed-top {
  left: 80px;
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .navbar.fixed-top {
  right: 80px;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
  width: 80px;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navbar-header .brand-text,
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .modern-nav-toggle {
  display: none;
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation-header {
  margin-left: 2.2rem;
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation-header {
  margin-right: 2.2rem;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation-header span {
  display: none;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation-header .feather-more-horizontal {
  display: block;
  font-size: 1.285rem;
  width: 18px;
  height: 18px;
}
[dir] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li:last-child {
  margin-bottom: 1.25rem !important;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
  color: #565656;
}
[dir] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
  background: whitesmoke;
  box-shadow: none;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded {
  width: 260px;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation > li.navigation-header span {
  display: block;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation > li.navigation-header .feather-more-horizontal {
  display: none;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub > a:after {
  content: "";
  height: 1rem;
  width: 1rem;
  display: inline-block;
  position: absolute;
  top: 14px;
  transition: all 0.2s ease-out;
}
[dir] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub > a:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  transform: rotate(0deg);
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub > a:after {
  right: 20px;
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub > a:after {
  left: 20px;
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub.open:not(.menu-item-closing) > a:after {
  transform: rotate(90deg);
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub.open:not(.menu-item-closing) > a:after {
  transform: rotate(-90deg);
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navbar-header .brand-text {
  display: inline;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .modern-nav-toggle {
  display: block;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation {
  overflow: visible;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li.navigation-header span {
  display: none;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a {
  text-overflow: inherit;
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .app-content, [dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .footer {
  margin-left: 80px;
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .app-content, [dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .footer {
  margin-right: 80px;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .header-navbar.floating-nav {
  width: calc(100vw - (100vw - 100%) - 4.4rem - 74px);
}
.vertical-layout.vertical-menu-modern.menu-collapsed .header-navbar.navbar-static-top {
  width: calc(100vw - (100vw - 100%) - 74px);
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .header-navbar.navbar-static-top {
  left: 74px;
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .header-navbar.navbar-static-top {
  right: 74px;
}
[dir=ltr] .vertical-layout.vertical-menu-modern .toggle-icon, [dir=ltr] .vertical-layout.vertical-menu-modern .collapse-toggle-icon {
  margin-right: 0.425rem;
}
[dir=rtl] .vertical-layout.vertical-menu-modern .toggle-icon, [dir=rtl] .vertical-layout.vertical-menu-modern .collapse-toggle-icon {
  margin-left: 0.425rem;
}
.vertical-layout.vertical-menu-modern .toggle-icon:focus,
.vertical-layout.vertical-menu-modern .collapse-toggle-icon:focus {
  outline: none;
}
@media (min-width: 992px) {
.vertical-layout.vertical-menu-modern .main-menu {
    width: 260px;
}
}
@media (max-width: 1199.98px) {
.vertical-layout.vertical-menu-modern .main-menu {
    width: 0;
}
.vertical-layout.vertical-menu-modern .navbar .navbar-header {
    width: 0;
}
[dir=ltr] .vertical-layout.vertical-menu-modern .content, [dir=ltr] .vertical-layout.vertical-menu-modern .footer {
    margin-left: 0;
}
[dir=rtl] .vertical-layout.vertical-menu-modern .content, [dir=rtl] .vertical-layout.vertical-menu-modern .footer {
    margin-right: 0;
}
[dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .app-content, [dir=ltr] .vertical-layout.vertical-menu-modern.menu-collapsed .footer {
    margin-left: 0;
}
[dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .app-content, [dir=rtl] .vertical-layout.vertical-menu-modern.menu-collapsed .footer {
    margin-right: 0;
}
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
    width: 0;
}
}
@media (max-width: 767.98px) {
.vertical-layout.vertical-menu-modern .main-menu {
    width: 0;
}
.vertical-layout.vertical-menu-modern .navbar .navbar-header {
    width: 0;
}
[dir=ltr] .vertical-layout.vertical-menu-modern .content, [dir=ltr] .vertical-layout.vertical-menu-modern .footer {
    margin-left: 0;
}
[dir=rtl] .vertical-layout.vertical-menu-modern .content, [dir=rtl] .vertical-layout.vertical-menu-modern .footer {
    margin-right: 0;
}
}
@keyframes fadein {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}
@keyframes fadeout {
from {
    opacity: 1;
}
to {
    opacity: 0;
}
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
[dir=ltr] .vertical-menu-modern.vertical-layout .main-menu .navigation > li > a > span {
    animation: none;
}
[dir=rtl] .vertical-menu-modern.vertical-layout .main-menu .navigation > li > a > span {
    animation: none;
}
}
/*=========================================================================================
    File Name: vertical-overlay-menu.scss
    Description: A overlay style vertical menu with show and hide support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
[dir=ltr] .vertical-overlay-menu .content {
  margin-left: 0;
}
[dir=rtl] .vertical-overlay-menu .content {
  margin-right: 0;
}
.vertical-overlay-menu .navbar .navbar-header {
  width: 260px;
}
[dir=ltr] .vertical-overlay-menu .navbar .navbar-header {
  float: left;
}
[dir=rtl] .vertical-overlay-menu .navbar .navbar-header {
  float: right;
}
.vertical-overlay-menu .main-menu, .vertical-overlay-menu.menu-hide .main-menu {
  opacity: 0;
  transition: width 0.25s, opacity 0.25s, transform 0.25s;
  width: 260px;
}
[dir] .vertical-overlay-menu .main-menu, [dir] .vertical-overlay-menu.menu-hide .main-menu {
  transform: translate3d(0, 0, 0);
}
[dir=ltr] .vertical-overlay-menu .main-menu, [dir=ltr] .vertical-overlay-menu.menu-hide .main-menu {
  left: -260px;
}
[dir=rtl] .vertical-overlay-menu .main-menu, [dir=rtl] .vertical-overlay-menu.menu-hide .main-menu {
  right: -260px;
}
.vertical-overlay-menu .main-menu .navigation > li > a > svg,
.vertical-overlay-menu .main-menu .navigation > li > a > i {
  transition: 200ms ease all;
  height: 20px;
  width: 20px;
}
[dir=ltr] .vertical-overlay-menu .main-menu .navigation > li > a > svg, [dir=ltr] .vertical-overlay-menu .main-menu .navigation > li > a > i {
  margin-right: 14px;
  float: left;
}
[dir=rtl] .vertical-overlay-menu .main-menu .navigation > li > a > svg, [dir=rtl] .vertical-overlay-menu .main-menu .navigation > li > a > i {
  margin-left: 14px;
  float: right;
}
.vertical-overlay-menu .main-menu .navigation > li > a > svg:before,
.vertical-overlay-menu .main-menu .navigation > li > a > i:before {
  transition: 200ms ease all;
  font-size: 1.429rem;
}
.vertical-overlay-menu .main-menu .navigation li.has-sub > a:after {
  content: "";
  height: 1rem;
  width: 1rem;
  display: inline-block;
  position: absolute;
  top: 14px;
  transition: all 0.2s ease-out;
}
[dir] .vertical-overlay-menu .main-menu .navigation li.has-sub > a:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  transform: rotate(0deg);
}
[dir=ltr] .vertical-overlay-menu .main-menu .navigation li.has-sub > a:after {
  right: 20px;
}
[dir=rtl] .vertical-overlay-menu .main-menu .navigation li.has-sub > a:after {
  left: 20px;
}
[dir=ltr] .vertical-overlay-menu .main-menu .navigation li.has-sub.open:not(.menu-item-closing) > a:after {
  transform: rotate(90deg);
}
[dir=rtl] .vertical-overlay-menu .main-menu .navigation li.has-sub.open:not(.menu-item-closing) > a:after {
  transform: rotate(-90deg);
}
.vertical-overlay-menu .main-menu .navigation .navigation-header .feather-more-horizontal {
  display: none;
}
.vertical-overlay-menu.menu-open .main-menu {
  opacity: 1;
  transition: width 0.25s, opacity 0.25s, transform 0.25s;
}
[dir=ltr] .vertical-overlay-menu.menu-open .main-menu {
  transform: translate3d(260px, 0, 0);
}
[dir=rtl] .vertical-overlay-menu.menu-open .main-menu {
  transform: translate3d(-260px, 0, 0);
}