.dropdown-menu-customer ul.dropdown-menu a.dropdown-item,
.dropdown-customer-actions ul.dropdown-menu a.dropdown-item {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.dropdown-menu-customer ul.dropdown-menu a.dropdown-item i.bi,
.dropdown-customer-actions ul.dropdown-menu a.dropdown-item i.bi {
  height: auto;
  width: auto;
  font-size: 1.2rem;
}